const { start } = require("@popperjs/core");
const {
  throttle
} = require("lodash");
// const { default: Swal } = require("sweetalert2");

require("./bootstrap");

//  gsap register scroll trigger plugin
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
gsap.config({
  nullTargetWarn: false
});

let tl_menu = null;

barba.use(barbaPrefetch, {
  limit: 5
});
barba.init({
  transitions: [{
    preventRunning: true,
    name: "opacity-transition",
    sync: true,

    // page leave barba init
    async leave(data) {
      const done = this.async();
      pageTransition();
      await delay(1000);
      done();
      data.current.container.remove();
    },

    async beforeEnter(data) {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    },

    // page enter barba init
    async enter(data) {
      gsap.to(".cursor", {
        opacity: 0,
      });
    },

    async after(data) {
      contentAnimation();
      checkAndRenderCaptcha();
      tl_menu.reverse(1.5);
      setTimeout(() => {
        resizeSlider();
        ScrollTrigger.refresh();
      }, 300);
      // window.scrollTo(0,0);
      // tl_menu.reversed() ? tl_menu.play() : tl_menu.reverse(1.5);
    },

    async once(data) {
      preload_first();
      await delay(1800);
      contentAnimation();
      checkAndRenderCaptcha(1);
      resizeSlider();
      window.scrollTo(0, 0);
    },
  },],
});

$(window).on("resize", function (e) {
  resizeSlider();
  $('.home_clients').slick('refresh');
});

barba.hooks.beforeLeave((data) => { });

barba.hooks.leave((data) => {
  unhoverFunc();
  hideDropdown();
})

barba.hooks.beforeEnter((data) => { })

barba.hooks.enter((data) => {
  $("a").hover(hoverFunc, unhoverFunc);
  $("button").hover(hoverFunc, unhoverFunc);
  window.scrollTo(0, 0);
  initEstimateFormPopup();
});


barba.hooks.after((data) => {
  ScrollTrigger.update();
  setTimeout(() => {
    forms.init();
    Scripts.init();
    resizeSlider();
    initAccordion();
    ScrollTrigger.refresh();
    if ($('.home_clients').length) {
      $('.home_clients').slick('refresh');
    }
  }, 300)
  initCountrySelect();
});

barba.hooks.once((data) => {
  $("a").hover(hoverFunc, unhoverFunc);
  $("button").hover(hoverFunc, unhoverFunc);
  forms.init();
  Scripts.init();
  // init();
  initAccordion();
  initCountrySelect();
  initEstimateFormPopup();
  $(window).on("resize", function (e) {
    resizeSlider();
  });

});

function delay(n) {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
}

$('[data-scroll-container]').on("click", "#portfolio_button", function () {
  setTimeout(() => {
    ScrollTrigger.batch(".portifolio_card_wrapper", {
      markers: false,
      start: "top 90%",
      end: "bottom 100%",
      trigger: ".cyan_head",
      onEnter: (batch) => {
        batch.forEach((my_portfolio_card, index) => {
          gsap.to(my_portfolio_card.children, {
            clipPath: "polygon(0 0,100% 0, 100% 100%, 0 100%)",
            ease: "Power4.in",
          })
        });
      },
    });
  }, 500)
});

$('[data-scroll-container]').on("click", "#home__modal", function (e) {
  e.preventDefault();
  $('#estimate-modal').modal('show');
});

$(".humbuger").on("click", function () {
  myAnimation();
});
$(".info_title").on("click", function () {

});

function initAccordion() {
  const accordionElemts = document.querySelectorAll(".accordion-header");

  for (let i = 0; i < accordionElemts.length; i++) {
    accordionElemts[i].addEventListener('click', () => {
      setTimeout(() => { }, 500)
    });
  }
}

/* Hide homepage dropdown card after routing. */

function hideDropdown() {
  // var dropdown_card = document.querySelector(".dropdown_card");
  // console.log(dropdown_card);
  $(".dropdown_card").css("visibility", "hidden");
  $('.dropdown_').off("mouseenter mouseleave");
}

// //testimonial slider 

function resizeSlider() {
  let testimonialHeight = $(".testimonial_slider").find(".slick-track").outerHeight();
  $(".testimonial_slider")
    .find(".slick-slide")
    .css("height", testimonialHeight + "px");
  let homeSliderHeight = $(".how_we_help .slide").find(".slick-track").outerHeight();
  $(".how_we_help .slide")
    .find(".slick-slide")
    .css("height", homeSliderHeight + "px");
}

function initCountrySelect() {
  //test for iterating over child elements
  let langArray = [];
  let imgArray = [];
  let home_langArray = [];
  let home_imgArray = [];
  $('.vodiapicker option').each(function () {
    let img = $(this).attr("data-thumbnail");
    let text = this.innerText;
    let value = $(this).val();
    let item = '<li role="button"><img src="' + img + '" alt="" value="' + value + '"/><span></span></li>';
    imgArray.push(img)
    langArray.push(item);
  })
  $('.home_vodiapicker option').each(function () {
    let img = $(this).attr("data-thumbnail");
    let text = this.innerText;
    let value = $(this).val();
    let item = '<li role="button"><img src="' + img + '" alt="" value="' + value + '"/><span></span></li>';
    home_imgArray.push(img)
    home_langArray.push(item);
  })

  $('#a').html(langArray);
  $('#home_a').html(home_langArray);
  $('#home_a_two').html(home_langArray)

  //Set the button value to the first el of the array
  $('.btn-select').html('<div ><img src="' + imgArray[0] + '" alt="" /><span><i class="evaicon icon-chevron-down arrow_down"></i></span></div>');
  $('.btn-select').attr('value', 'ind');
  $('.home-btn-select').html('<div ><img src="' + home_imgArray[0] + '" alt="" /><span><i class="evaicon icon-chevron-down arrow_down"></i></span></div>');
  $('.home-btn-select').attr('value', 'ind');

  //change button stuff on click
  $('#a li').click(function () {
    let img = $(this).find('img').attr("src");
    let value = $(this).find('img').attr('value');
    let text = this.innerText;
    let item = '<div ><img src="' + img + '" alt="" /><span><i class="evaicon icon-chevron-down arrow_down"></i></span></div>';
    $('.btn-select').html(item);
    $('.btn-select').attr('value', value);
    changeBudgetOptions(value);
    $(".b").toggle();
  });
  $('#home_a li').click(function () {
    let img = $(this).find('img').attr("src");
    let value = $(this).find('img').attr('value');
    let text = this.innerText;
    let item = '<div ><img src="' + img + '" alt="" /><span><i class="evaicon icon-chevron-down arrow_down"></i></span></div>';
    $('.home-btn-select').html(item);
    $('.home-btn-select').attr('value', value);
    changeBudgetOptions(value, 'home');
    $(".home_b").toggle();
  });
  $('#home_a_two li').click(function () {
    let img = $(this).find('img').attr("src");
    let value = $(this).find('img').attr('value');
    let text = this.innerText;
    let item = '<div ><img src="' + img + '" alt="" /><span><i class="evaicon icon-chevron-down arrow_down"></i></span></div>';
    $('.home-btn-select').html(item);
    $('.home-btn-select').attr('value', value);
    changeBudgetOptions(value, 'home_two');
    $(".home_b").toggle();
  });

  $(".btn-select").click(function () {
    $(".b").toggle();
  });
  $(".home-btn-select").click(function () {
    $(".home_b").toggle();
  });
}

function changeBudgetOptions(value, form) {
  let us = ["< 5k USD", "5-10k USD", "10-20k USD", "30-40k USD", "40-50k USD", "> 50k USD"]
  let uae = ["< 5k AED", "5-10k AED", "10-20k AED", "30-40k AED", "40-50k AED", "> 50k AED"]
  let ind = ["< 5 lakh", "5-10 lakh", "10-20 lakh", "20-30 lakh", "30-50 lakh", "> 50 lakh"]
  if (form == 'home') {
    document.getElementById("home_budget_options").innerHTML = '';
  } else if (form == 'home_two') {
    document.getElementById("home_budget_options_two").innerHTML = '';
  } else {
    document.getElementById("budget_options").innerHTML = '';
  }

  let arr;
  if (value == "us") {
    arr = us
  } else if (value == "uae") {
    arr = uae
  } else {
    arr = ind
  }
  arr.map((budget) => {
    let item = `<label class="estimate_chkbox_wrp estimate_chkbox_wrp_sm">
                <input type="checkbox" name="budget" id="budget-${budget}" value="${budget}" />
                <div class="chkbx_btn_wrpr chkbx_btn_wrpr_sm">
                  ${budget}<span class="btn_bg"></span>
                </div>
              </label>`
    let home_item = `<label class="estimate_chkbox_wrp estimate_chkbox_wrp_sm home_estimate_chkbox_wrp">
                <input type="checkbox" name="budget" id="budget-${budget}" value="${budget}" />
                <div class="chkbx_btn_wrpr chkbx_btn_wrpr_sm home_chkbx_btn_wrpr">
                  ${budget}<span class="btn_bg"></span>
                </div>
              </label>`
    if (form == 'home') {
      $('#home_budget_options').append(home_item)
    } else if (form == 'home_two') {
      $('#home_budget_options_two').append(home_item)
    } else {
      $('#budget_options').append(item)
    }
  })
  $(".estimate_form_cntnr .estimate_chkbox_wrp , .blog_category_wrpr .estimate_chkbox_wrp").on(
    "mouseenter",
    function (e) {
      var n = $(this).find(".btn_bg");

      gsap.to(n, {
        width: "100%",
        duration: 1.5,
        ease: "power3.out",
      });
    }
  ),
    $(".estimate_form_cntnr .estimate_chkbox_wrp, .blog_category_wrpr .estimate_chkbox_wrp").on(
      "mouseleave",
      function (e) {
        var n = $(this).find(".btn_bg");

        gsap.to(n, {
          width: "0%",
          duration: 1.5,
          ease: "power3.out",
        });
      }
    )
  $(".estimate_chkbox_wrp input:checkbox").on("change", function () {
    if ($(this).is(":checked")) $(this).parent().addClass("toggle_active");
    else $(this).parent().removeClass("toggle_active");
  });
}

const endsWithBlog = function (url) {
  const regex = /\/blog\/.*$/;
  return regex.test(url);
};

function initEstimateFormPopup() {
  let home_modal_shown = sessionStorage.getItem("home_modal_shown");
  let windowLoc = $(location).attr("pathname");

  if (!home_modal_shown && endsWithBlog(windowLoc)) {
    setTimeout(() => {
      $('#estimate-modal').modal('show');
    }, 3500)
    sessionStorage.setItem('home_modal_shown', true);
    home_modal_shown = true;
  }
  $("#get_a_quote").on("click", function (e) {
    e.preventDefault();
    $('#estimate-modal').modal('show');
  });
}


//Get the button:
$("#scrollTopBtn").on("click", function () {
  window.scrollTo(0, 0)
});
$('[data-scroll-container]').on("click", "#go_to_form", function () {
  // locoScroll.scrollTo("top");
});
$(".scrolldwn_arrw").on("click", function () {
  var viewport_height = $(window).height();
  window.scrollTo(0, viewport_height);
});

//reset youtube video on modal close.
// $('#video_modal').on('click', function () {
//   var video = $("#eva_video").attr("src");
//   $("#eva_video").attr("src","");
//   $("#eva_video").attr("src",video);
// });

//nav hover



//cmmn preldr anim
function pageTransition() {
  // gsap.set('.logoTwo',{scale:2})
  var animTwo = gsap
    .timeline({
      paused: true,
      duration: 0.1,
      ease: "power2.inOut",
    })
    .to(
      ".part01", {
      x: "+=32",
    },
      0
    )
    .to(
      ".part02", {
      y: "+=32",
    },
      0
    )
    .to(
      ".part03", {
      y: "-=32",
    },
      0
    )
    .to(
      ".part04", {
      x: "-=32",
    },
      0
    );

  // animTwo.reverse();
  var tl = gsap.timeline();

  tl.to(".transition span", {
    duration: 1.2,
    scaleY: 1.2,
    transformOrigin: "top right",
    skewY: 2,
    stagger: 0.2,
    ease: "power3.inOut",
  })

    .to(
      ".logoTwo", {
      opacity: 1,
    },
      ">-0.6"
    )

    .add(function () {
      animTwo.play();
      // animTwo.reverse();
    })

    .to(
      ".logoTwo", {
      opacity: 0,
    },
      ">+0.5"
    )

    .to(
      ".transition span", {
      duration: 1.2,
      scaleY: 0,
      transformOrigin: "top right",
      stagger: 0.1,
      delay: 0.1,
      ease: "power3.inOut",
    },
      ">-0.6"
    )

    .add(function () {
      animTwo.reverse();
    });
}

function textRevealAnim() {
  // alert('test')
  var tl = gsap.timeline();
  var tl_textreveal = gsap.timeline();
  tl_textreveal
    .from(".logistic_title", {
      y: 50,
      opacity: 0,
      duration: 0.8,
      delay: 1,
    })

    /*.from(
            " .header_tl .subhead_tl", {
                y: 50,
                opacity: 0,
                duration: 0.7,
            },
            ">-0.5"
        )*/
    .from(
      ".logistic_subhead", {
      y: 50,
      opacity: 0,
      duration: 0.7,
    },
      ">-0.5"
    )

    .from(
      ".logisitic_eva_img", {
      opacity: 0,

      duration: 1.3,
      ease: "Power4.inOut",
      // delay: 0.03,
      // scale: 1.4,
      // delay: -1,
      // duration: 4
    },
      ">-0.7"
    ); //
  tl.from(".we_are", {
    y: 50,
    opacity: 0,
    duration: 0.7,
    delay: 1,
  })
    .from(
      ".content_heading", {
      y: 50,
      opacity: 0,
      duration: 0.8,
      // delay: 1
    },
      ">-0.5"
    )
    .from(
      ".reviews_wrpr", {
      y: 50,
      opacity: 0,
      duration: 0.8,
      // delay: 1
    },
      ">-0.5"
    )
    .from(
      ".content_sub_head", {
      y: 50,
      opacity: 0,
      duration: 0.7,
    },
      ">-0.5"
    )
    .from(
      ".btn_cntnr", {
      y: 50,
      opacity: 0,
      duration: 0.7,
    },
      ">-0.5"
    )
    .from(
      ".new_home_slider_btns", {
      y: 50,
      opacity: 0,
      duration: 0.7,
    },
      ">-0.5"
    )
    .from(
      ".content_sec .content", {
      y: 50,
      opacity: 0,
      duration: 0.5,
    },
      ">-0.8"
    )
    .fromTo(
      ".content_image_wrapper", {
      clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
      ease: "Power4.in",
    }, {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      // duration: 0.03,
      ease: "Power4.inOut",
      duration: 0.6,
      // ease: easeInOut
    },
      ">-0.8"
    )
    .from(
      ".content_image_wrapper img", {
      scale: 1.3,
      duration: 1.3,
      ease: "Power4.inOut",
      // delay: 0.03,
      // scale: 1.4,
      // delay: -1,
      // duration: 4
    },
      ">-0.7"
    );
}

function preload_first() {
  // scale loader down
  //preloader on load screen variables---------------------------
  var loadingInner = $(".loading-screen");
  // var load_text = $('.lodr_txt');

  gsap.set(loadingInner, {
    autoAlpha: 1,
  });

  gsap
    .timeline({
      // delay: 0.4
    })
    .to(".logo_wrpr .logoOne", {
      height: "55px",
      duration: 0.3,
      borderRadius: 0,
      // transformOrigin: "0% 0%"
      // ease: power3.out
    })
    .to(".logo_wrpr .logoOne", {
      // height: "60px",
      width: "55px",
      borderRadius: 0,
      duration: 0.3,
      // ease: power3.out
    })
    .from(".logoOne .part", {
      scale: 0,
      transformOrigin: "50% 50%",
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.05,
    })
    .to(
      ".logoOne .part01", {
      x: "+=25",
      y: "+=2"
    },
      1.2
    )
    .to(
      ".logoOne .part02", {
      y: "+=25",
      x: "-=2"
    },
      1.2
    )
    .to(
      ".logoOne .part03", {
      y: "-=25",
      x: "+=2"
    },
      1.2
    )
    .to(
      ".logoOne .part04", {
      x: "-=25",
      y: "-=2"
    },
      1.2
    )
    .to(".logo_wrpr .logoOne", {
      left: "-15%",
      duration: 1.2,
      ease: Power3.easeOut,
    })
    .to(
      ".logo_wrpr .logo_txt", {
      // display: "block",
      x: 0,
      opacity: 1,
      duration: 1,
    },
      ">-1"
    )
    .to(
      ".logo_wrpr .logo_txt .reveal_bg", {
      width: "0%",
      duration: 0.7,
      ease: Power3.easeOut,
    },
      ">-1"
    )
    .to(
      ".logo_wrpr", {
      autoAlpha: 0,
      delay: 0.1,
    },
      ">-0.3"
    )

    .fromTo(
      loadingInner, {
      duration: 0.2,
      scaleY: 1,
      transformOrigin: "bottom left",
    }, {
      duration: 0.8,
      scaleY: 0,
      skewX: 0,
      transformOrigin: "top left",
      ease: "power3.out",
    },
      ">-0.1"
    );
}

/************************
 *
 * mouse cursor animation gsap
 *
 * **************************/
// CURSOR
var $circle = $(".circle");

function moveCircle(e) {
  gsap.to($circle, {
    x: e.clientX,
    y: e.clientY,
    ease: "power2.out",
  });
}

const cursorEye = document.querySelector(".cursor-eye");
const cursor_txt = document.querySelector(".cursor_txt");

function mousemoveHandler(e) {
  const target = e.target;
  const tl = gsap.timeline({
    defaults: {
      x: e.clientX,
      y: e.clientY,
      ease: "power2.out",
    },
  });
  if (target.closest(".portfolio_crd_wrpr")) {
    cursor_txt.innerHTML = 'Explore';
    tl.to(cursorEye, {
      opacity: 1,
    }).to(
      ".circle", {
      opacity: 0,
    },

      "-=0.5"
    );
  } else if (target.closest(".portifolio_card_wrapper")) {
    cursor_txt.innerHTML = 'Explore';
    tl.to(cursorEye, {
      opacity: 1,
    }).to(
      ".circle", {
      opacity: 0,
    },

      "-=0.5"
    );
  } else if (target.closest(".ir_pro_demo")) {
    cursor_txt.innerHTML = 'Play Now';
    tl.to(cursorEye, {
      opacity: 1,
    }).to(
      ".circle", {
      opacity: 0,
    },

      "-=0.5"
    );
  } else {
    tl.to(cursorEye, {
      opacity: 0,
    }).to(
      ".circle", {
      opacity: 1,
    },
      "-=0.5"
    );
  }
}


function mouseleaveHandler() {
  gsap.to(".circle", {
    opacity: 0,
  });
}
document.addEventListener("mousemove", mousemoveHandler);
document.addEventListener("mouseleave", mouseleaveHandler);

function hoverFunc(e) {
  gsap.to($circle, {
    opacity: 1,
    scale: 3.5,
    duration: 0.3,
    ease: "Power4.inOut",
  });
}

function unhoverFunc(e) {
  gsap.to($circle, {
    opacity: 1,
    scale: 1,
    duration: 0.3,
    ease: "Power4.inOut",
  });
}

$(window).on("mousemove", moveCircle);
// >>end<<

/**
 * Click to open humburger menu and close menu
 *
 * */

// const init = () => {
let menuLayer = $(".hamburger-menu");
let animationTime = 0.5;
let bar1 = $(".humbuger").find(".top_bar");
let bar2 = $(".humbuger").find(".bottom_bar");

tl_menu = gsap
  .timeline({
    reversed: true,
  })
  .fromTo(
    bar1, {
    transformOrigin: "0% 0%",
    ease: "power3.inOut",
    background: "white",
  }, {
    rotationZ: 45,
    y: "9",
    transformOrigin: "50% 50%",
    ease: "power3.inOut",
    duration: animationTime,
  },
    0.05
  )
  .fromTo(
    bar2, {
    transformOrigin: "0% 0%",
    ease: "power3.inOut",
    background: "white",
  }, {
    rotationZ: -45,
    y: "-1",
    width: "100%",
    transformOrigin: "50% 50%",
    ease: "power3.inOut",
    duration: animationTime,
  },
    0.05
  )
  .to(
    menuLayer, {
    duration: 0.6,
    ease: "power3.inOut",
    height: "120vh",
  },
    ">-0.2"
  )
  .from(
    ".menu_content .menu_links .glitch", {
    y: 50,
    opacity: 0,
    duration: 0.6,
    ease: "power3.inOut",
    stagger: {
      amount: 0.5,
    },
  },
    0.2
  )
  .from(
    ".info_box .text_box .cntnt_wrpr", {
    y: 50,
    opacity: 0,
    duration: 1.2,
    ease: "power4.out",
    stagger: {
      amount: 0.25,
    },
  },
    0.5
  );
// };

/**
 * toggle an animation / diff speed = timeScale
 *
 * */

// function toggleMenu() {
//   // const menu = playHamburgerMenu();
//   console.log(menu.reversed(), "menu reversed");

//   menu.reversed(!menu.reversed());
//   // menu.reversed() ? menu.timeScale(1).play() : menu.timeScale(1.5).reverse();
// }


// $('.dropdown_').each(function () {
// let $dropdown = $(this)
// $dropdown.mouseenter(function () {
//   $dropdown.find('.dropdown_card').removeClass('hidden');
//   $dropdown.find('.dropdown_pointer').removeClass('hidden');
// })
// $dropdown.mouseleave(function () {
//   $dropdown.find('.dropdown_card').addClass('hidden');
//   $dropdown.find('.dropdown_pointer').addClass('hidden');
// })
// })
// $(".nxt_prjct_sec").on("mouseenter", function (e) {
//   var circle = $(this).find(".bg_circle");

const myAnimation = () => {
  // tl_menu.reversed(!tl_menu.reversed());
  tl_menu.reversed() ? tl_menu.play(0) : tl_menu.reverse(1.5);
};

function textRevealOpen() {
  gsap.from(".menu_content .menu_links li a", {
    y: 50,
    opacity: 0,
    duration: 1.6,
    ease: "power4.out",
    stagger: {
      amount: 0.5,
    },
  });
}

function textRevealClose() {
  gsap.to(".menu_content .menu_links li a", {
    y: 50,
    opacity: 0,
    duration: 1.6,
    ease: "power4.out",
    stagger: {
      amount: 0.5,
    },
  });
}



async function reCaptchaOnFocus(formId, siteKey) {
  if (typeof grecaptcha !== 'undefined') {
    return;
  }

  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit&hl=en`;
  script.async = true;
  head.appendChild(script);
}

// function initCaptcha(formId) {
//   let siteKey = '6LeSexMmAAAAAMaUrHKvDtYKADnbHKhxUGzc2pjI';
//   // let siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; //test key for development purposes.
//   var form = document.getElementById(formId);
//   if (!form) return;
//   var inputs = form.querySelectorAll('input,textarea');
//   var renderCaptchaFlag = false;

//   inputs.forEach(function (input) {
//     input.addEventListener('input', async function () {
//       reCaptchaOnFocus(formId, siteKey);
//       if (typeof grecaptcha !== 'undefined') {
//         if (!renderCaptchaFlag) {
//           grecaptcha.render("recaptcha-" + formId, {
//             sitekey: siteKey,
//             callback: function (token) {
//               $("#" + formId + "-recaptcha").attr('value', token);
//             }
//           });

//           renderCaptchaFlag = true;
//         }
//       }
//     });
//   });
// }
function initCaptcha(formId) {
  let siteKey = '6LeSexMmAAAAAMaUrHKvDtYKADnbHKhxUGzc2pjI';
  // let siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; //test key for development purposes.
  var form = document.getElementById(formId);
  if (!form) return;

  reCaptchaOnFocus(formId, siteKey);

  // Polling to check if grecaptcha is loaded
  let intervalId = setInterval(function () {
    if (typeof grecaptcha !== 'undefined') {
      clearInterval(intervalId);
      grecaptcha.render("recaptcha-" + formId, {
        sitekey: siteKey,
        callback: function (token) {
          $("#" + formId + "-recaptcha").attr('value', token);
        }
      });
    }
  }, 100);
}

function checkAndRenderCaptcha(parms) {
  let windowLoc = $(location).attr("pathname");
  initCaptcha('estimate-form-contact');
  if (windowLoc == '/get-an-estimate') {
    initCaptcha('estimate-form-main')
    initCaptcha('estimate-form-top')
  }
  if (parms) {
    initCaptcha('estimate-form-modal')
  }
}


// Example usage for each form on the page
// Add more forms as needed
// renderCaptcha('other-form-id', 'other-site-key');
// renderCaptcha('another-form-id', 'another-site-key');


// if(windowLoc == '/estimate'){
//   grecaptcha.render("recaptcha-main", {
//     sitekey: '6LeSexMmAAAAAMaUrHKvDtYKADnbHKhxUGzc2pjI',
//     callback: function (token) {
//       $('#estimate-form-main-recaptcha').attr('value',token)
//     }
//   });
//   grecaptcha.render("recaptcha-top", {
//     sitekey: '6LeSexMmAAAAAMaUrHKvDtYKADnbHKhxUGzc2pjI',
//     callback: function (token) {
//       $('#estimate-form-top-recaptcha').attr('value',token)
//     }
//   });
// }
// if(parms){
//   grecaptcha.render("recaptcha-modal", {
//     sitekey: '6LeSexMmAAAAAMaUrHKvDtYKADnbHKhxUGzc2pjI',
//     callback: function (token) {
//       $("#estimate-form-modal-recaptcha").attr('value',token)
//     }
//   });
// }

function contentAnimation(container) {
  // text revel
  textRevealAnim();

  function logScroll() {
    if (window.scrollY <= 50) {
      $("#header").removeClass("filter");
    } else {
      $("#header").addClass("filter");
    }

    if (window.scrollY <= 300) {
      $("#top_page_nav").removeClass("top_tab_view");
    } else {
      $("#top_page_nav").addClass("top_tab_view");
    }
  }
  window.addEventListener('scroll', throttle(logScroll, 100, {
    leading: true
  }));

  // scrolltotop button functionlty
  $("#scrollTopBtn").css({
    opacity: 0,
  });

  function addScrollBtn() {
    if (window.scrollY <= 100) {
      $("#scrollTopBtn").css({
        opacity: 0,
      });
    } else {
      // console.log("scroll");
      $("#scrollTopBtn").css({
        opacity: 1,
      });
    }
  }

  window.addEventListener('scroll', throttle(addScrollBtn, 1000))
  // end


  // scrolld own arrow anim
  gsap.to(".scrolldwn_arrw i", {
    duration: 0.6,
    x: 20,
    ease: "circ.in",
    // ease: 'power3.inOut',
    repeat: -1,
    yoyo: true,
  });

  //Scrolltrigger animation who we are
  let tl_who = gsap.timeline({
    // yes, we can add it to an entire timeline!
    scrollTrigger: {
      trigger: ".we_craft_bannr",
      // pin: true, // pin the trigger element while active,
      // scrub: 1,
      markers: false,
      start: "top 50%",
      end: "bottom 50%",
      // toggleActions: "play none none reverse",
      // scroller: ".data-scroll-container",
    },
  });

  tl_who
    .to(".we_craft_bannr_img", {
      width: "100%",
      duration: 0.2,
      ease: "Power3.inOut",
    })
    .from(".we_craft_bannr_img img", {
      scale: 1.3,
      duration: 0.7,
      ease: "Power4.inOut",
      // delay: 0.03
    });

  //Scrolltrigger animation
  gsap.set(".service_banner_img", {
    clipPath: "polygon(100% 0, 30% 0, 30% 100%, 100% 100%)",
    ease: "Power3.out",
  });
  let tl = gsap.timeline({
    // yes, we can add it to an entire timeline!
    scrollTrigger: {
      markers: false,
      trigger: ".service_banner_wrapper",
      start: "20% 70%",
      end: "90% 70%",
      // toggleActions: "play none none reverse",
      // scroller: ".data-scroll-container",
    },
  });

  tl.to(
    ".service_banner_img", {
    clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
    duration: 0.01,
    ease: "Power4.inOut",
  },
    ">-0.05"
  ).from(".service_banner_img img", {
    scale: 1.3,
    duration: 0.6,
    ease: "Power4.inOut",
    // delay: 0.03
  });

  //Scrolltrigger animation portfolio
  ScrollTrigger.batch(".portifolio_card_wrapper", {
    markers: false,
    start: "top 90%",
    end: "bottom 100%",
    trigger: ".portifolio_card_wrapper",
    // toggleActions: "play none none reverse",
    // scroller: ".data-scroll-container",
    onEnter: (batch) => {
      batch.forEach((my_portfolio_card, index) => {
        gsap.to(my_portfolio_card.children, {
          clipPath: "polygon(0 0,100% 0, 100% 100%, 0 100%)",
          ease: "Power4.in",
          // stagger: 0.5,
          delay: index * 0.3,
          duration: 0.5,
        })
      }
      );
    },
    // onLeaveBack: batch => {
    //     batch.forEach((my_portfolio_card, index) => gsap.to(my_portfolio_card.children, {
    //         clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
    //         duration: 1,
    //         ease: "Power4.inOut",
    //         stagger: 0.5,
    //         delay: index * 0.3,
    //         duration: 1
    //     }))
    // }
  });

  /**
   * reveal nimation of crsspltfrm section
   *
   * */

  var containers_crsspltfrm = gsap.utils.toArray(".info_list_sectn");

  gsap.set(".info_list_sectn .eva_im_wrap .eva_img", {
    scale: 1.3,
  });

  containers_crsspltfrm.forEach((container) => {
    const img = container.querySelectorAll(".eva_im_wrap .eva_img");
    const txt = container.querySelectorAll(
      ".info_list_sectn .eva_txt_cont .txt_anim"
    );

    var tl_1 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        markers: false,
        // toggleActions: "play none none reverse",
        start: "top 60%",
        end: "70% 30%",
        // scroller: ".data-scroll-container",
      },
    });

    tl_1.from(txt, {
      y: 20,
      opacity: 0,
      duration: 0.5,
      stagger: 0.15,
      ease: "Power4.inOut",
    });
    tl_1.to(
      img, {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      ease: "Power4.inOut",
      stagger: 0.5,
      // delay: 0.3,
      duration: 1,
      scale: 1,
      opacity: 1,
    },
      ">-1"
    );
  });

  /**
   *
   * services sectn animation
   *
   *
   */

  var containers_services = gsap.utils.toArray(".service_block");

  containers_services.forEach((container) => {
    const icn_wrpr = container.querySelectorAll(".icn_wrpr");
    const img = container.querySelectorAll(".icn_wrpr img");
    const txt = container.querySelectorAll(".eva_txt_cont .txt_anim");

    var tl_2_2 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        // markers: true,
        // toggleActions: "play none none reverse",
        start: "top 70%",
        end: "70% 30%",
        // scroller: ".data-scroll-container",
      },
    });

    tl_2_2.to(icn_wrpr, {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      ease: "Power3.inOut",
    });
    tl_2_2.from(
      img, {
      scale: 1.3,
      duration: 0.8,
      ease: "Power4.inOut",
    },
      ">-0.8"
    );
    tl_2_2.from(
      txt, {
      y: 20,
      opacity: 0,
      duration: 0.3,
      stagger: 0.15,
      ease: "Power4.inOut",
    },
      ">-0.5"
    );
  });

  /**
   * icon grid animation
   *
   *
   */
  var tl_11 = gsap.timeline({
    scrollTrigger: {
      trigger: ".icon_grid  ",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 70%",
      end: "30% 70%",
      // scroller: ".data-scroll-container",
    },
  });

  tl_11.from(".icon_title ", {
    y: 50,
    opacity: 0,
    duration: 0.8,
    delay: 0.1,
  }); //

  var tl_12 = gsap.timeline({
    scrollTrigger: {
      trigger: ".current_openings_table  ",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 70%",
      end: "30% 70%",
      // scroller: ".data-scroll-container",
    },
  });

  tl_12
    .from(".table_content_1 ", {
      y: -50,
      opacity: 0,
      duration: 0.8,
      delay: 0.1,
    })
    .from(".table_content_2 ", {
      y: -50,
      opacity: 0,
      duration: 0.8,
      delay: 0.1,
    })
    .from(".table_content_3 ", {
      y: -50,
      opacity: 0,
      duration: 0.8,
      delay: 0.1,
    })
    .from(".table_content_4 ", {
      y: -50,
      opacity: 0,
      duration: 0.8,
      delay: 0.1,
    })
    .from(".table_content_5 ", {
      y: -50,
      opacity: 0,
      duration: 0.8,
      delay: 0.1,
    }); //

  var tl_9 = gsap.timeline({
    scrollTrigger: {
      trigger: ".timeline",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 80%",
      end: "30% 70%",
      // scroller: ".data-scroll-container",
    },
  });

  tl_9.from(".l_anim_one ", {
    x: -50,
    opacity: 0,
    duration: 0.5,
    stagger: 0.1,
    ease: "Power4.inOut",
  });
  tl_9.to(
    ".l_anim_one ", {
    x: 0,
    opacity: 1,
    duration: 0.3,
    stagger: 0.1,
    ease: "Power4.inOut",
  },
  );
  tl_9.from(".r_anim_one ", {
    x: 40,
    opacity: 0,
    duration: 0.5,
    stagger: 0.1,
    ease: "Power4.inOut",
  });
  tl_9.to(
    ".r_anim_one ", {
    x: 20,
    opacity: 0,
    duration: 0.3,
    stagger: 0.1,
    ease: "Power4.inOut",
  },
    ">-.7"
  );

  tl_9.from(".l_anim_two ", {
    x: -50,
    opacity: 0,
    duration: 0.7,
    stagger: 0.1,
    ease: "Power4.inOut",
  });
  tl_9.to(
    ".l_anim_two ", {
    x: 20,
    opacity: 0,
    duration: 0.4,
    stagger: 0.1,
    ease: "Power4.inOut",
  },
    ">-1.5"
  );
  tl_9.from(".r_anim_two ", {
    x: 40,
    opacity: 0,
    duration: 0.7,
    stagger: 0.1,
    ease: "Power4.inOut",
  });
  tl_9.to(
    ".r_anim_two ", {
    x: 20,
    opacity: 0,
    duration: 0.4,
    stagger: 0.1,
    ease: "Power4.inOut",
  },
    ">-1.5"
  );
  //

  // tech_stk sectn animation
  let tl_4 = gsap.timeline({
    scrollTrigger: {
      trigger: ".tech_stck_anim",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 90%",
      end: "bottom -90%",
      // scroller: ".data-scroll-container",
    },
  });

  tl_4.from(".tech_stck_anim .txt_container .txt_anim", {
    y: 20,
    opacity: 0,
    duration: 0.7,
    stagger: 0.35,
    ease: "Power4.inOut",
  });
  tl_4.from(
    ".tech_stck_anim .img_cntnr .group_img", {
    opacity: 0,
    duration: 0.7,
    ease: "Power4.inOut",
  },
    ">-0.9"
  );
  tl_4.from(
    ".tech_stck_anim .txt_box .txt_anim", {
    y: 20,
    opacity: 0,
    duration: 0.7,
    stagger: 0.35,
    ease: "Power4.inOut",
  },
    ">-0.7"
  );
  tl_4.from(
    ".mob_tech_stack_wrpr .type", {
    x: -20,
    opacity: 0,
    duration: 0.5,
    stagger: 0.15,
    ease: "Power4.inOut",
  },
    ">-0.9"
  );
  tl_4.from(
    ".mob_tech_stack_wrpr .info_wrp .txt_anim", {
    y: 50,
    // opacity: 0,
    duration: 0.7,
    stagger: 0.15,
    // ease: "Power4.inOut",
  },
    ">-0.9"
  );

  //our services sectn animtn
  var containers = gsap.utils.toArray(".our_services_sectn");

  containers.forEach((container) => {
    const txts = container.querySelectorAll(".eva_txt_cont .txt_anim");
    const cards = container.querySelectorAll(".our_servce_card");

    var tl_5 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        // markers: true,
        // toggleActions: "play none none reverse",
        start: "top 80%",
        end: "80% 30%",
        // scroller: ".data-scroll-container",
      },
    });

    tl_5.from(txts, {
      y: 20,
      opacity: 0,
      duration: 0.5,
      stagger: 0.35,
      ease: "Power4.inOut",
    });
    tl_5.from(
      cards, {
      x: -20,
      opacity: 0,
      duration: 0.3,
      stagger: 0.25,
      ease: "Power4.inOut",
    },
      ">-0.6"
    );
  });

  //our clients sectn animtn
  var client_logos = gsap.utils.toArray(".clients_sec");

  client_logos.forEach((container) => {
    const cards = container.querySelectorAll(".client_item");

    var tl_5 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        // markers: true,
        // toggleActions: "play none none reverse",
        start: "top 80%",
        end: "80% 30%",
        // scroller: ".data-scroll-container",
      },
    });

    tl_5.from(
      cards, {
      x: -20,
      opacity: 0,
      duration: 0.5,
      stagger: 0.1,
      ease: "Power4.inOut",
    },
      ">-0.1"
    );
  });

  //design service animatn

  var tl_6 = gsap.timeline({
    scrollTrigger: {
      trigger: ".design_servce",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 90%",
      end: "30% 90%",
      // scroller: ".data-scroll-container",
    },
  });
  tl_6.from(".design_service_card_wrapper", {
    y: 20,
    opacity: 0,
    duration: 1,
    stagger: 0.35,
    ease: "Power4.inOut",
  });

  /**
   * reveal nimation of or core values
   *
   */
  var containers_who_we_value = gsap.utils.toArray(".who_we_our_value_sectn");

  containers_who_we_value.forEach((container) => {
    const txts = container.querySelectorAll(
      ".who_we_our_value_sectn .eva_txt_cont .txt_anim"
    );
    const txt_bg = container.querySelectorAll(
      ".who_we_our_value_sectn .eva_txt_cont .txt_anim ._bg"
    );
    const img = container.querySelectorAll(
      ".who_we_our_value_sectn .eva_im_wrap .eva_img"
    );

    var tl_7 = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        markers: false,
        toggleActions: "play none none reverse",
        start: "top 80%",
        end: "80% 30%",
        // scroller: ".data-scroll-container",
      },
    });

    tl_7.from(txts, {
      y: 50,
      opacity: 0,
      duration: 0.5,
      stagger: 0.2,
      ease: "Power4.inOut",
    });
    tl_7.to(
      txt_bg, {
      width: "70%",
      duration: 0.5,
      ease: "Power4.inOut",
    },
      ">-0.4"
    );
    tl_7.from(
      img, {
      opacity: 0,
      duration: 0.3,
      ease: "Power4.inOut",
    },
      ">-0.5"
    );
  });

  /**
   * commn txt reveal animation
   * 
   */



  /**
   * prtfolio nxt prdct arrw
   *  
   * */
  $(".nxt_prjct_sec").on("mouseenter", function (e) {
    var circle = $(this).find(".bg_circle");

    var path_a = $(this).find("#Path_679");
    var path_b = $(this).find("#Path_680");
    var viewBox = $(this).find("svg");

    var tl = gsap.timeline();

    tl.to(
      circle, {
      scale: 1.2,
      ease: "power3.out",
      duration: 1,
    },
      0.1
    )
      .to(
        viewBox, {
        attr: {
          width: "156",
          viewBox: "0 0 156 60.242",
        },
      },
        0.1
      )
      .to(
        path_a, {
        attr: {
          d: "M7.5,18h153",
          // transform: "translate(-7.5 10)"
        },
      },
        0.1
      )
      .to(
        path_b, {
        attr: {
          // d: "M18,18h153",
          transform: "translate(107 -7.5)",
        },
      },
        0.1
      );
  }),
    $(".nxt_prjct_sec").on("mouseleave", function (e) {
      var circle = $(this).find(".bg_circle");
      var path_a = $(this).find("#Path_679");
      var path_b = $(this).find("#Path_680");
      var viewBox = $(this).find("svg");

      var tl = gsap.timeline();

      tl.to(
        circle, {
        scale: 1,
        ease: "power3.out",
        duration: 1,
      },
        0.1
      )
        .to(
          viewBox, {
          attr: {
            width: "111",
            viewBox: "0 0 111 60.243",
          },
        },
          0.1
        )
        .to(
          path_a, {
          attr: {
            d: "M7.5,18h108",
            // transform: "translate(-7.5 10)"
          },
        },
          0.1
        )
        .to(
          path_b, {
          attr: {
            // d: "M18,18h153",
            transform: "translate(62 -7.5)",
          },
        },
          0.1
        );
    });
  // end

  /**
   *  blog page animation
   * 
   */
  // : image scale

  $(".latest_blog_wrpr").on("mouseenter", function (e) {
    var blgImg = $(this).find(".blog_img");

    blgImg.addClass("active");
  }),
    $(".latest_blog_wrpr").on("mouseleave", function (e) {
      var blgImg = $(this).find(".blog_img");

      blgImg.removeClass("active");
    });

  // :latest blog_anim
  var tl_latst_blog = gsap.timeline({
    scrollTrigger: {
      trigger: ".latest_blog_wrpr",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 70%",
      end: "30% 90%",
      // scroller: ".data-scroll-container",
    },
  });
  tl_latst_blog.from(".latest_blog_wrpr", {
    y: 60,
    opacity: 0,
    duration: 1,
    ease: "power3.easeOut",
    // delay: 3
  });

  // :blog cards anim

  blogCardAnimation();

  // end

}

// >>end<<

// :blog cards anim

function blogCardAnimation() {
  var tl_all_blog = gsap.timeline({
    scrollTrigger: {
      trigger: ".all_blogs_wrpr",
      markers: false,
      // toggleActions: "play none none reverse",
      start: "top 80%",
      end: "30% 90%",
      // scroller: ".data-scroll-container",
    },
  });

  tl_all_blog.from(".blog_card_wrpr", {
    y: 60,
    opacity: 0,
    duration: 0.8,
    ease: "power3.easeOut",
    stagger: 0.5,
  });
}


var forms = {
  init: function () {
    // contact-form
    $("#contact-frm").on("submit", function (e) {
      e.preventDefault();
      let form = $(this)
      let form_id = $(this).attr("id");
      $("." + form_id + "-frame").addClass("loading");

      let url = $(this).attr("action");
      getInputValue = (id) => form.find(`input[name=${id}]`).val()
      getTextareaValue = (id) => form.find(`textarea[name=${id}]`).val()
      let formData = {
        _token: $("#token").val(),
        email: getInputValue('email'),
        name: getInputValue('name'),
        desc: getTextareaValue('desc'),
        captcha_token,
      }
      $.post(
        url,
        formData,
        function (response) {
          if (response.code == 400) {
            for (let key in formData) {
              if (response.msg.hasOwnProperty(key)) {
                let error = '<span class="err_msg">' + response.msg[key] + '</div>';
                form.find("#" + key + "_err").html(error);
              } else {
                form.find("#" + key + "_err").html(null);
              }
            }


            $("." + form_id + "-frame").removeClass("loading");
          } else if (response.code == 200) {
            document.getElementById('home_route_link').click();

            $("." + form_id + "-frame" + " .loader").addClass("complete");
            let success = "<div>" + response.msg + "</div>";
            $("#res").html(success);

            form.find("#email").val(""), $("#name").val(""), $("#desc").val("");

            for (let key in formData) {
              form.find("#" + key + "_err").html(null);
              form.find("#" + key).val("");
            }

            setTimeout(function () {

              $("#res").html(null);
              $("." + form_id + "-frame").removeClass("loading");
              $("." + form_id + "-frame" + " .loader").removeClass("complete");
            }, 3000);
          }
        }
      );

    });

    $(".clickable-row").click(function () {
      window.location = $(this).data("href");
    });

    $(".minus").click(function () {
      var $input = $(this).parent().find("input");
      var count = parseInt($input.val()) - 1;
      count = count < 1 ? 0 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $(".plus").click(function () {
      var $input = $(this).parent().find("input");
      $input.val(parseInt($input.val()) + 1);
      $input.change();
      return false;
    });

    $("#actual-btn").on("change", function (e) {
      // console.log("change fire");
      var i = $(this).prev("label").clone();
      var file = $("#actual-btn")[0].files[0].name;
      // console.log(file);
      let successfile = "remove file " + file + "/ choose another file";
      // let successfile= "<label for='actual-btn' class='attachtext'><span><picture> <source srcset='{{asset('/images/svg/attachment-icon.svg')}}' type='image/webp'><img src='{{asset('/images/svg/attachment-icon.svg')}}' alt=''></picture></span><span id='#attachdata'>"+file+"</span></label>"
      //$(this).prev('label').html(successfile);
      $(this).prev("label").text(successfile);
      $(this).prev("label").css("color", "white");
    });

    $("#apply-frm").on("submit", function (e) {
      e.preventDefault();
      let form = $(this)
      let form_id = form.attr('id')
      $("." + form_id + "-frame").addClass("loading");
      var frmdta = new FormData();
      frmdta.append("file", $("#actual-btn")[0].files[0]);
      frmdta.append("email", $("#email").val());
      frmdta.append("name", $("#name").val());
      frmdta.append("cover_letter", $("#cover_letter").val());
      frmdta.append("career_opening_id", $("#career_opening_id").val());
      frmdta.append("experience_in", $("#experience_in").val());
      frmdta.append("experience", $("#experience").val());
      frmdta.append("phone", $("#phone").val());
      frmdta.append("_token", $("#token").val());

      $.ajax({
        url: $(this).attr("action"),
        type: "POST",
        // data: $('#estimate_frm').serialize(),
        data: frmdta,
        cache: false,
        processData: false,
        contentType: false,
        // data: JSON.stringify(data),
        //processData: false,
        // contentType: "application/json; charset=UTF-8",
        success: function (response) {
          if (response.code == 400) {
            // $("#btn").attr('disabled', false);
            if (response.msg.name) {
              let error =
                '<span class="err_msg">' + response.msg.name + "</div>";
              $("#name_err").html(error);
            } else {
              $("#name_err").html(null);
            }

            if (response.msg.desc) {
              let error =
                '<span class="err_msg">' + response.msg.desc + "</div>";
              $("#desc_err").html(error);
            } else {
              $("#desc_err").html(null);
            }

            if (response.msg.phone) {
              let error =
                '<span class="err_msg">' + response.msg.phone + "</div>";
              $("#phone_err").html(error);
            } else {
              $("#phone_err").html(null);
            }

            if (response.msg.email) {
              let error =
                '<span class="err_msg">' + response.msg.email + "</div>";
              $("#email_err").html(error);
            } else {
              $("#email_err").html(null);
            }

            if (response.msg.experience) {
              let error =
                '<span class="err_msg">' + response.msg.experience + "</div>";
              $("#experience_err").html(error);
            } else {
              $("#experience_err").html(null);
            }

            if (response.msg.experience_in) {
              let error =
                '<span class="err_msg">' +
                response.msg.experience_in +
                "</div>";
              $("#experiencein_err").html(error);
            } else {
              $("#experiencein_err").html(null);
            }

            if (response.msg.cover_letter) {
              let error =
                '<span class="err_msg">' + response.msg.cover_letter + "</div>";
              $("#cover_letter_err").html(error);
            } else {
              $("#cover_letter_err").html(null);
            }

            if (response.msg.file) {
              let error =
                '<span class="err_msg">' + response.msg.file + "</div>";
              $("#resume_err").html(error);
            } else {
              $("#resume_err").html(null);
            } // let error = '<div class="alert alert-danger">' + response.msg +
            //     '</div>';
            // $("#res").html(error);

            $(".frame").removeClass("loading");
          } else if (response.code == 200) {
            // $("#btn").attr('disabled', false);
            // let success = '<div class="alert alert-success">' + response.msg +
            //     '</div>';
            // $("#res").html(success);

            $(".frame .loader").addClass("complete");
            let success = "<div>" + response.msg + "</div>";
            $("#res").html(success);

            // Swal.fire({
            //   icon: "success",
            //   title: response.msg,
            //   showConfirmButton: true,
            //   confirmButtonText: "Close",
            // });

            document.getElementById("apply-frm").reset();

            $("#name_err").html(null),
              $("#email_err").html(null),
              $("#phone_err").html(null),
              $("#experience_err").html(null),
              $("#experiencein_err").html(null),
              $("#cover_letter_err").html(null),
              $("#resume_err").html(null);
          }
        },
      });
    });

    // end
    // estimate form description count
    $('[data-scroll-container]').on("input", "#description", function (e) {
      let text = `${e.target.value.length}/1000`
      $("#chara_length").html(text);
    })
    // contact form description count
    $('[data-scroll-container]').on("input", "#desc", function (e) {
      let text = `${e.target.value.length}/500`
      $("#chara_length").html(text);
    })

    // estimate form - clear validation on input change
    $(".estimate-form").on("input", function (e) {
      let form = $(this);
      let form_id = form.attr('id');
      removeClassFromElementInForm(form_id, e.target.id, '_err')
      // (e.target.name == 'stream') ? form.find("#stream_err").html(null) : (e.target.name == 'budget') ? form.find("#budget_err").html(null) :
      //   (e.target.name == 'name') ? form.find("#name_err").html(null) : (e.target.name == 'phone') ? form.find("#phone_err").html(null) :
      //     (e.target.name == 'email') ? form.find("#email_err").html(null) : (e.target.name == 'company') ? form.find("#company_err").html(null) :
      //       form.find("#desc_err").html(null);
    })

    // contact form - clear validation on input change
    $('#contact-frm').on("input", function (e) {
      let form = $(this);
      let form_id = form.attr('id');
      removeClassFromElementInForm(form_id, e.target.id, '_err')
      // (e.target.name == 'name') ? form.find("#name_err").html(null) : (e.target.name == 'email') ? form.find("#email_err").html(null) :
      //   form.find("#desc_err").html(null);
    })

    $(".estimate-form").on("submit", function (e) {
      e.preventDefault();
      let form = $(this)
      let form_id = form.attr('id')
      $("." + form_id + "-frame").addClass("loading");
      let budget_data = new Array();
      form.find("input[name='budget']:checked").each(function () {
        budget_data.push($(this).val());
      });
      let stream_data = new Array();
      form.find("input[name='stream']:checked").each(function () {
        stream_data.push(parseInt($(this).val()));
      });
      getInputValue = (id) => form.find(`input[name=${id}]`).val()
      getTextareaValue = (id) => form.find(`textarea[name=${id}]`).val()
      getSelection = (id) => form.find(`select[name=${id}]`).val()

      let formData = {
        _token: $("#token").val(),
        email: getInputValue("email"),
        name: getInputValue("name"),
        description: getInputValue("description"),
        phone: getInputValue("phone"),
        company: getInputValue("company"),
        heard_from: getSelection("heard_from"),
        main: getInputValue("main"),
        stream: stream_data.join(","),
        budget: budget_data.join(","),
        source_id: 1,
        captcha_token: $("#" + form_id + "-recaptcha").val()
      };

      $.ajax({
        url: form.attr("action"),
        type: "POST",
        data: formData,
        success: function (response) {
          if (response.code == 400) {
            for (let key in formData) {
              if (response.msg.hasOwnProperty(key)) {
                let error = '<span class="err_msg">' + response.msg[key] + '</div>';
                form.find("#" + key + "_err").html(error);
                addClassToElementInForm(form_id, key, '_err');
              } else {
                form.find("#" + key + "_err").html(null);
                removeClassFromElementInForm(form_id, key, '_err');
              }
            }

            setTimeout(function () {
              $("." + form_id + "-frame").removeClass("loading");
            }, 300)

          } else if (response.code == 200) {
            $('#estimate-modal').modal('hide');
            document.getElementById('home_route_link').click();
            $("." + form_id + "-frame").removeClass("loading");
            form.reset();
            form.find("input[type=checkbox]").each(function () {
              this.checked = false;
              if ($(this).is(":checked"))
                $(this).parent().addClass("toggle_active");
              else $(this).parent().removeClass("toggle_active");
            });

            for (let key in formData) {
              form.find("#" + key + "_err").html(null);
            }

          }
        },
      });
    })

    function addClassToElementInForm(formId, elementId, className) {
      var form = document.getElementById(formId);
      if (!form) return;  // Return if the form is not found

      var element = form.querySelector("#" + elementId);
      if (element) {
        element.classList.add(className);
      }
    }

    function removeClassFromElementInForm(formId, elementId, className) {
      var form = document.getElementById(formId);
      if (!form) return;  // Return if the form is not found

      var element = form.querySelector("#" + elementId);
      if (element) {
        element.classList.remove(className);
      }
    }

    // blog load
    var windowLoc = $(location).attr("pathname"); //jquery format to get window.location.pathname
    var isBlogLoading = false;
    var hasBlogs = true;

    if (windowLoc == "/blog") {
      var SITEURL = window.location.href;
      var page = 1; //track user scroll as page number, right now page number is 1
      let category = 9999;

      function loadBlogs() {
        if (!isBlogLoading && hasBlogs) {
          isBlogLoading = true

          page = page + 1;
          load_more(page);
        }
      }
      const intersectionObserver = new IntersectionObserver(function (entries) {
        if (entries[0].isIntersecting) {
          loadBlogs();
        }
      });
      // start observing
      intersectionObserver.observe(document.querySelector("#footer"));

      function load_more(page) {
        $.ajax({
          url: SITEURL + "?page=" + page + '&category=' + category,
          type: "get",
          datatype: "html",
          beforeSend: function () {
            $(".ajax-loading").show();
          },
        })
          .done(function (data) {

            isBlogLoading = false

            if (data.html == "") {
              hasBlogs = false
              $(".ajax-loading").hide();
              return;
            }

            $("#blog-wrapper").append(data.html);
            $(".ajax-loading").hide(); //hide loading animation once data is received
            // locoScroll.update();
          })
          .fail(function (jqXHR, ajaxOptions, thrownError) {
            isBlogLoading = false;
            alert("No response from server");
          });
      }

      function getBlogs(id) {
        hasBlogs = true;
        category = id;
        page = 1;
        $('#blog-wrapper').addClass('d-none');
        $('#blog-card-skelton').removeClass('d-none');
        $.ajax({
          url: SITEURL + "?category=" + id,
          type: "get",
          datatype: "html",
          beforeSend: function () {
            // $(".ajax-loading").show();
          },
        })
          .done(function (data) {

            isBlogLoading = false

            $('#blog-wrapper').removeClass('d-none');
            $('#blog-card-skelton').addClass('d-none');

            if (data.html == "") {
              hasBlogs = false
              // $(".ajax-loading").hide("");
              return;
            }


            $("#blog-wrapper").html(data.html);
            // $(".ajax-loading").hide(); //hide loading animation once data is received
            blogCardAnimation();
            // locoScroll.update();
            // ScrollTrigger.refresh();
          })
          .fail(function (jqXHR, ajaxOptions, thrownError) {
            isBlogLoading = false;
            alert("No response from server");
          });
      }
      // blog category checkbox
      $(".blog_category").on("change", function () {
        if ($(this).prop('checked')) {
          $(".blog_categories input[type='checkbox']").not(this).prop('checked', false);
        }
        let checkedCount = $(".blog_categories input[type='checkbox']:checked").length;
        if (checkedCount === 0) {
          $(this).prop('checked', true);
        }
        $(".blog_categories .blog_category").each(function () {
          if ($(this).is(":checked")) $(this).parent().addClass("toggle_active");
          else $(this).parent().removeClass("toggle_active");
        })
        let category_id = $(this).attr("id")
        getBlogs(category_id)
      });
    }
    // end
    //comment form.

    $('#comment_label').click(() => {
      $("#comment_input").focus();
    });
    let isValid = sessionStorage.getItem("isValid")
    let comment_name = sessionStorage.getItem("name")
    let user_id = sessionStorage.getItem('user_id')

    $('#avatar_txt').html(comment_name ? comment_name : "");
    // console.log(isValid,'isValid')

    $('#user_id').val(user_id ? user_id : "");

    $("#comment_input").focus(function () {
      if (!isValid) {
        // alert("input focus");
        $('#comment_modal').modal('show');
        // isValid = true;
        $("#comment_input").blur();
      }
    });


    $("#comment-contactUs-form").on("submit", function (e) {
      e.preventDefault();

      $(".eva_frame").addClass("loading");

      // console.log($('#f_name').val(),$('#l_name').val(),$('#email').val(),);

      let url = $(this).attr("action");

      $.post(
        url, {
        _token: $("#token").val(),
        email: $("#contact_us_email").val(),
        f_name: $("#f_name").val(),
        l_name: $("#l_name").val(),
      },
        function (response) {
          if (response.code == 400) {
            // $("#btn").attr('disabled', false);
            if (response.msg.f_name) {
              let error =
                '<span class="err_msg">' + response.msg.f_name + "</div>";
              $("#f_name_err").html(error);
            } else {
              $("#f_name_err").html(null);
            }

            if (response.msg.l_name) {
              let error =
                '<span class="err_msg">' + response.msg.l_name + "</div>";
              $("#l_name_err").html(error);
            } else {
              $("#l_name_err").html(null);
            }

            if (response.msg.email) {
              let error =
                '<span class="err_msg">' + response.msg.email + "</div>";
              $("#email_err").html(error);
            } else {
              $("#email_err").html(null);
            }

            $(".eva_frame").removeClass("loading");
            // let error = '<div class="alert alert-danger">' + response.msg + '</div>';
            // $("#res").html(error);
          } else if (response.code == 200) {
            $(".eva_frame .loader").addClass("complete");
            let success = "<div>" + response.msg + "</div>";
            $("#res").html(success);

            $("#contact_us_email").val(""), $("#f_name").val(""), $("#l_name").val("");
            isValid = true;
            sessionStorage.setItem('user_id', response.data[0].id);
            sessionStorage.setItem("isValid", true);
            sessionStorage.setItem("name", response.name)
            $('#avatar_txt').html(response.name);

            $('#user_id').val(response.data[0].id);

            $("#name_err").html(null),
              $("#email_err").html(null),
              $("#desc_err").html(null);

            setTimeout(function () {
              $("#res").html(null);
              $(".eva_frame").removeClass("loading");
              $(".eva_frame .loader").removeClass("complete");
              $('#comment_modal').modal('hide');
            }, 3000);
          }
        }
      );
    });


    $("#comment-form").on("submit", function (e) {
      e.preventDefault();

      // console.log($('#user_id').val(),$('#blog_id').val(),$('#comment').val(),);

      $(".frame").addClass("loading");

      let url = $(this).attr("action");

      $.post(
        url, {
        _token: $("#token").val(),
        comment: $("#comment_input").val(),
        user_id: $("#user_id").val(),
        content_id: $("#content_id").val(),
        content_type: $("#content_type").val(),
      },
        function (response) {
          if (response.code == 400) {
            // $("#btn").attr('disabled', false);

            if (response.msg.comment) {
              let error =
                '<span class="err_msg">' + response.msg.comment + "</div>";
              $("#comment_err").html(error);
            } else {
              $("#comment_err").html(null);
            }

            $(".frame").removeClass("loading");

            // $(".frame").removeClass("loading");
          } else if (response.code == 200) {
            // $(".frame .loader").addClass("complete");
            let success = "<div>" + response.msg + "</div>";
            $("#res").html(success);

            $("#comment_input").val("");

            $(".frame").removeClass("loading");

            $("#comment_err").html(null);
            let comments = '';
            $("#comment_list").empty();
            const getName = (string) => {
              let name = string.split(' ')
              let f_Name = name[0].charAt(0).toUpperCase();
              let l_Name = name[1].charAt(0).toUpperCase();
              return f_Name + l_Name;
            }
            $.each(response.comments, function (i, v) {
              comments += `<div class='d-flex'>
              <div class='avatar_wrppr mx-2 mb-auto'>
                  <span class="avatar_text ">${getName(v.name)}</span>
              </div>
              <div class="comment_wrppr ms-2 flex-fill">
                  <div class="d-flex mb-2">
                      <span class="comment_name">${v.name}</span>
                      <span class="comment_time">${v.commentTime}</span>
                  </div>
                  <p class="comment">${v.comment}</p>
                  ${v.rplyComment ?
                  `<div class="comment_rply">
                    <div class="d-flex">
                      <div class="avatar_wrppr mx-2 mb-auto">
                          <span class="avatar_text ">${getName(v.rplyName)}</span>
                      </div>
                      <div class="comment_wrppr ms-2 flex-fill">
                          <div class="d-flex mb-2">
                              <span class="comment_name">${v.rplyName}</span>
                              <span class="comment_time">${v.rplyTime}</span>
                          </div>
                          <p class="comment">${v.rplyComment}</p>
                      </div>
                    </div>
                  </div>`: ''}
              
                  <hr class="comment_hr"/>
              </div>
          </div>`
            });
            $('#comment_list').append(comments);

            // setTimeout(function () {
            //   $("#res").html(null);
            //   $(".frame").removeClass("loading");
            //   $(".frame .loader").removeClass("complete");
            //   $('#comment_modal').modal('hide');
            // }, 3000);
          }
        }
      );

    });

    // $('#comment_modal').addEventListener('shown.bs.modal', function () {
    //   $('#comment_input').focus();
    // });

  },
};


function animation() {
  gsap.timeline().from(
    ".number_stack .txt_anim", {
    y: 20,
    opacity: 0,
    duration: 0.7,
    stagger: 0.35,
    ease: "Power4.inOut",
  }
  );
}

function animationTwo() {
  gsap.timeline().from(".cmmn_txt_anim .eva_txt_cont .txt_anim", {
    y: 20,
    opacity: 0,
    duration: 0.5,
    stagger: 0.35,
    ease: "Power4.inOut",
  });
}

// Intersection Observer callback function
function handleIntersection(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      animation(); // Play the GSAP animation when the container is in the viewport
      observer.unobserve(entry.target); // Stop observing the container after animation is triggered
    }
  });
}

function requestIntersection(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      animationTwo(); // Play the GSAP animation when the container is in the viewport
      observer.unobserve(entry.target); // Stop observing the container after animation is triggered
    }
  });
}


// /** evalogical timeline */
// function initTimeline() {

//   let parent_container = document.getElementById("section-timeline");
//   let timeline_container = parent_container.querySelector(".timeline-container");
//   var sections = timeline_container.querySelectorAll(".year");

//   const vh = (coef) => window.innerHeight * (coef / 100);

//   let parentST = ScrollTrigger.create({
//     id: "parent-timeline",
//     trigger: parent_container,
//     start: 'top top',
//     toggleClass: 'started',
//     pin: true,
//     scrub: 0.5,
//     markers: true,
//     end: () => "+=" + ((sections.length - 1) * vh(40)),
//   });

//   let currentSection;

//   function goto(section, i) {
//     if (currentSection !== section) { // if the section is the currentSection, skip
//       // move the container
//       // gsap.to(timeline_container, {
//       //   y: -48 * i,
//       //   duration: 0.6,
//       //   overwrite: true
//       // });
//       let tl = gsap.timeline({
//         defaults:
//         {
//           overwrite: true,
//           ease: "power3.out"
//         }
//       });
//       // animate OUT the current section (if there is one)
//       if (currentSection) {
//         tl.to(currentSection.querySelector("h2"), {
//           fontSize: "4rem",
//           color: "#fff"
//         });
//         tl.to(currentSection, {
//           maxHeight: "8rem"
//         }, 0);
//         tl.to(currentSection.querySelectorAll(".content"), {
//           opacity: 0.5,
//           duration: 0.25,
//           maxHeight: "0%"
//         }, 0);
//       }
//       currentSection = section;
//       // animate IN the new section (if there is one)
//       if (section) {
//         tl.to(section.querySelector("h2"), {
//           fontSize: "6rem",
//           color: "#07ffcd"
//         }, 0);
//         tl.to(section, {
//           maxHeight: "80vh"
//         }, 0);
//         // tl.fromTo(section.querySelectorAll(".content"), { maxHeight: "0%" }, {
//         //   opacity: 1,
//         //   duration: 0,
//         //   maxHeight: "100%"
//         // });
//       }
//     }
//   }


//   sections.forEach((sct, i) => {
//     let sct_index = sct.getAttribute('data-count');
//     // let s = sct.addClass('active');
//     if (i === 0) {
//       goto(sct, sct_index);
//     }
//     ScrollTrigger.create({
//       start: () => parentST.start + i * window.innerHeight * 0.4,
//       end: () => "+=" + window.innerHeight * 0.4,
//       markers: true,
//       //onLeaveBack: () => i || goto(null, 0),
//       toggleClass: { targets: sct, className: 'active' },
//       onToggle: self => self.isActive && goto(sct, sct_index)
//     });
//   });

// }

var Scripts = {
  init: function () {
    //** slick dot issue fix */
    // Initialize Slick
    $('.slick-slider').slick();

    // Add a short delay to allow Slick to render the dots
    setTimeout(function () {
      updateSlickDots();
    }, 500); // Adjust the delay if necessary

    // Function to update the slick dots roles and aria attributes
    function updateSlickDots() {
      $('.slick-dots').attr('role', 'tablist');
      $('.slick-dots li').each(function (index) {
        console.log('Selected LI:', $(this)); // Debugging
        $(this).attr('role', 'tab')
          .attr('aria-selected', $(this).hasClass('slick-active') ? 'true' : 'false')
          .attr('aria-controls', 'panel-' + (index + 1));

        $(this).find('button').removeAttr('role')
          .attr('aria-label', 'Slide ' + (index + 1));
      });
    }
    // console.log('scripts init');

    //** show and hide navbar on scroll */
    gsap.set(".topNav", {
      yPercent: 0
    });
    if (window.location.pathname !== "/") {
      const showAnim = gsap.from('.topNav', {
        yPercent: -100,
        paused: true,
        duration: 0.2
      }).progress(1);

      ScrollTrigger.create({
        start: "top top",
        markers: false,
        end: "max",
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse()
        }
      });
    }

    setTimeout(function () {
      $('.dropdown_').on("mouseenter", function (e) {
        // alert('test');
        var dropdown_card = $(this).find(".dropdown_card");
        dropdown_card.css("visibility", "visible");
      });

      $('.dropdown_').on("mouseleave", function (e) {
        var dropdown_card = $(this).find(".dropdown_card");
        dropdown_card.css("visibility", "hidden");
      });
    }, 1000);


    //* counter animation
    let counter_numbers = document.querySelectorAll('.counter-number');

    let tl_13 = gsap.timeline({
      scrollTrigger: {
        trigger: '.home_number_stack',
        start: 'top 80%',
        end: 'bottom 80%',
        markers: false
      }
    })

    counter_numbers.forEach((element) => {
      const endValue = parseInt(element.getAttribute('data-count'), 10);

      tl_13.fromTo(element, {
        textContent: 0
      }, {
        textContent: endValue,
        duration: 2,
        ease: "power4.out",
        snap: { textContent: 1 },
        onUpdate: function () {
          element.textContent = Math.ceil(element.textContent) + '+';
        },
      }, 0);
    });


    /**
     * calls tach stack image animation in services page
     */
    // Select your image elements
    gsap.set(".stack_img_anim", {
      scale: 0
    });
    const imageElements = document.querySelectorAll(".stack_img_anim");
    function animateTechStackImages(currentImageIndex) {
      const image = imageElements[currentImageIndex];
      // Define scaling properties
      const startScale = 0;
      const endScale = 1;

      // Use GSAP timeline for animation
      gsap.timeline({
        onComplete: () => {
          const nextIndex = (currentImageIndex + 1) % imageElements.length;
          animateTechStackImages(nextIndex);
        }
      }).fromTo(image, {
        scale: startScale
      }, {
        scale: endScale,
        duration: 0.6,
        ease: "power3.out"
      })
        .to(image, {
          scale: startScale,
          duration: 0.4
        })
    }
    animateTechStackImages(0);


    let pathName = window.location.pathname
    if (pathName == '/') {
      var vidDefer = document.getElementsByTagName('iframe');
      for (var i = 0; i < vidDefer.length; i++) {
        if (vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
        }
      }
    }

    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $('.nav_link').each(function () {
      $(this).removeClass('active');
      if (this.href === path) {
        $(this).addClass('active');
      }
    });

    $(".toggle").click(function (e) {
      e.preventDefault();

      let $this = $(this);

      if ($this.next().hasClass("show")) {
        $this.next().removeClass("show");
        $this.next().slideUp(350);
      } else {
        $this.find(" .inner").removeClass("show");
        $this.find(" .inner").slideUp(350);
        $this.next().toggleClass("show");
        $this.next().slideToggle(350);
      }
    });

    $(".inner_isotope").isotope({
      // options
      itemSelector: ".Main_port",
      layoutMode: "fitRows",
    });
    $(".ui-group .filters .button").on("click", function (e) {
      e.preventDefault();

      $(".ui-group .filters .button").removeClass("is-checked");
      $(this).addClass("is-checked");

      //   var filter = $(this).attr("data-filter");
      var filters = {};

      var $button = $(e.currentTarget);
      // get group key
      var $buttonGroup = $button.parents(".button-group");
      var filterGroup = $buttonGroup.attr("data-filter-group");
      // set filter for group
      filters[filterGroup] = $button.attr("data-filter");
      // combine filters
      var filterValue = concatValues(filters);

      // console.log(filterValue);

      $(".inner_isotope").isotope({
        filter: filterValue,
      });

      ScrollTrigger.refresh();
    });

    // flatten object by concatting values
    function concatValues(obj) {
      var value = "";
      for (var prop in obj) {
        value += obj[prop];
      }
      return value;
    }

    // custom video playre theme
    const player = new Plyr(".video_port", {
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "fullscreen",
        "pip",
      ],
      tooltips: {
        controls: true,
      },
      fullscreen: {
        iosNative: true,
      },
      autoplay: false,
    });

    // modal for video player
    var $modalOverlay = $(".modal-overlay"),
      $modalContainer = $(".Eva_modal_container"),
      $modalTrigger = $(".modal-trigger"),
      $modalClose = $(".modal-close");

    $modalTrigger.on("click", function () {
      $modalContainer.toggleClass("modal--show");
      player.play();
    });

    $modalOverlay.on("click", function () {
      $modalContainer.toggleClass("modal--show");
      // click overlay stop the player
      player.stop();
    });

    $modalClose.on("click", function () {
      $modalContainer.removeClass("modal--show");
      //  plyr video stop the running video
      player.stop();
    });

    ScrollTrigger.create({
      start: 'top -350',
      end: 99999,
      markers: false,
      toggleClass: {
        className: 'scrolled',
        targets: '.topNav'
      }
    });

    // sliders

    $(".our-client-logo").slick({
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      slidesToShow: 5,
      slidesToScroll: 2,
      // centerMode: true,
      variableWidth: false,
      dots: false,
      arrows: false,
      infinite: true,
      pauseOnHover: false,
      rtl: true,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      ],
    }).on('init afterChange', function (event, slick, currentSlide) {
      $('.slick-active').attr('aria-hidden', 'false');
      $('.slick-slide').not('.slick-active').attr('aria-hidden', 'true');
    });

    $(".how_we_help .slide").slick({
      autoplay: false,
      speed: 600,
      autoplaySpeed: 5000,
      cssEase: "ease-out",
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      dots: false,
      fade: false,
      infinite: true,
      arrows: false,
      draggable: true,
      pauseOnHover: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          dots: true,
        },
      },],
    });

    $('#home_next').click(function () {
      $('.how_we_help .slide').slick('slickNext');
    });

    $('#home_prev').click(function () {
      $('.how_we_help .slide').slick('slickPrev');
    });

    let $home_btns = $('.home_slider_btns .slider_btn');

    $home_btns.click(function () {
      var slideIndex = $(this).data('slide-index');
      $('.how_we_help .slide').slick('slickGoTo', slideIndex); // Go to the selected slide
    });

    let $home_btns_new = $('.new_home_slider_btns .slider_btn_wrpr');

    $home_btns_new.click(function () {
      var slideIndex = $(this).data('slide-index');
      $('.how_we_help .slide').slick('slickGoTo', slideIndex); // Go to the selected slide
    });

    $('.how_we_help .slide').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      // Remove the 'active' class from all headings
      $home_btns.removeClass('active');
      // Add the 'active' class to the heading corresponding to the active slide
      let next_slide = $home_btns.filter('[data-slide-index="' + nextSlide + '"]');
      next_slide.addClass('active');

      $home_btns_new.removeClass('active');
      // Add the 'active' class to the heading corresponding to the active slide
      let next_slide_ = $home_btns_new.filter('[data-slide-index="' + nextSlide + '"]');
      next_slide_.addClass('active');
    });

    $(".working_at_eva .slide").slick({
      autoplay: true,
      // speed: 7000,
      autoplaySpeed: 6000,
      cssEase: "ease-in",
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      dots: true,
      infinite: true,
      arrows: false,
      draggable: true,
    });

    $(".testimonial_slider").slick({
      autoplay: true,
      // autoplaySpeed: 1000,
      slidesToShow: 2,
      slidesToScroll: 2,
      centerMode: false,
      // variableWidth: true,
      dots: false,
      arrows: true,
      infinite: true,
      draggable: true,
      arrows: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }],
    });
    $('#testimonial_next').click(function () {
      $('.testimonial_slider').slick('slickNext');
    });

    $('#testimonial_prev').click(function () {
      $('.testimonial_slider').slick('slickPrev');
    });

    //portfolio slider
    $(".portfolio_slider").slick({
      autoplay: false,
      // autoplaySpeed: 1000,
      slidesToShow: 3,
      slidesToScroll: 2,
      centerMode: false,
      // variableWidth: true,
      dots: false,
      arrows: false,
      infinite: true,
      draggable: true,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }],
    });
    $('#portfolio_next').click(function () {
      $('.portfolio_slider').slick('slickNext');
    });

    $('#portfolio_prev').click(function () {
      $('.portfolio_slider').slick('slickPrev');
    });

    //expertise slider
    $(".expertise_slider").slick({
      autoplay: false,
      // autoplaySpeed: 1000,
      slidesToShow: 4,
      slidesToScroll: 2,
      centerMode: false,
      // variableWidth: true,
      dots: false,
      arrows: false,
      infinite: true,
      draggable: true,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          autoplay: true
        },
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          autoplay: true
        }
      }],
    });
    $('#expertise_next').click(function () {
      $('.expertise_slider').slick('slickNext');
    });

    $('#expertise_prev').click(function () {
      $('.expertise_slider').slick('slickPrev');
    });


    $(".unique_slider").slick({
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      // variableWidth: true,
      dots: false,
      arrows: true,
      fade: true,
      speed: 300,
      // cssEase: 'ease-in-out',
      infinite: true,
      draggable: true,
      arrows: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },],
    });
    $('#unique_next').click(function () {
      $('.unique_slider').slick('slickNext');
    });

    $('#unique_prev').click(function () {
      $('.unique_slider').slick('slickPrev');
    });

    let $headings = $('.headings .heading')
    let $dotted_lines = $('.headings .vertical_dotted_line')

    $headings.click(function () {
      var slideIndex = $(this).data('slide-index');
      $('.unique_slider').slick('slickGoTo', slideIndex); // Go to the selected slide

      // Remove the 'active' class from all headings and add it to the clicked heading
      $headings.removeClass('active');
      $(this).addClass('active');
      $dotted_lines.removeClass('active');
      $(this).next('.vertical_dotted_line').addClass('active')
    });

    $('.unique_slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      // Remove the 'active' class from all headings
      $headings.removeClass('active');
      $dotted_lines.removeClass('active');
      // Add the 'active' class to the heading corresponding to the active slide
      let next_slide = $headings.filter('[data-slide-index="' + nextSlide + '"]');
      next_slide.addClass('active');
      next_slide.next('.vertical_dotted_line').addClass('active')
    });

    $(".comment_form_section .blogs_inner_card").slick({
      autoplay: true,
      // speed: 4000,
      cssEase: "ease-out",
      // autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 3,
      // centerMode: true,
      variableWidth: false,
      dots: false,
      arrows: false,
      infinite: true,
      responsive: [{
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      ],
    });


    $(".home_clients .rtl").slick({
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      slidesToShow: 5,
      slidesToScroll: 1,
      variableWidth: false,
      dots: false,
      arrows: false,
      infinite: true,
      rtl: true,
      pauseOnHover: false,
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      ]
    }).on('init afterChange', function (event, slick, currentSlide) {
      $('.slick-active').attr('aria-hidden', 'false');
      $('.slick-slide').not('.slick-active').attr('aria-hidden', 'true');
    });


    $(".our_team_slider").slick({
      autoplay: true,
      autoplaySpeed: 2000,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      infinite: true,
      draggable: false,
      pauseOnHover: false
    });

    $(".timeline_slider").slick({
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      infinite: true,
      draggable: false,
    });

    // >>end<<

    // btn animation
    $(".eva_btn").on("mouseenter", function (e) {
      let btnCls = $(this).find(".btn_bg");
      let icnCls = $(this).find(".evaicon");
      btnCls[0].style.left = '0';
      // btnCls[1].style.left = '0';
      btnCls[0].style.right = '';
      // btnCls[1].style.right = '';
      gsap.to(btnCls, {
        width: "100%",
        ease: "power3.out",
        duration: 0.6,
      }),
        gsap.to(icnCls, {
          x: "2",
          ease: "power3.out",
          duration: 1,
        });
    }),
      $(".eva_btn").on("mouseleave", function (e) {
        let btnCls = $(this).find(".btn_bg");
        let icnCls = $(this).find(".evaicon");
        btnCls[0].style.right = '0'
        // btnCls[1].style.right = '0'
        btnCls[0].style.left = ''
        // btnCls[1].style.left = ''
        gsap.to(btnCls, {
          width: "0",
          ease: "power3.out",
          duration: 0.6,
        }),
          gsap.to(icnCls, {
            x: "0",
            ease: "power3.out",
            duration: 1,
          });
      });

    // let btn = $(".eva_btn");
    // btn.on('mousemove', e => {
    //   let rect = e.target.getBoundingClientRect();
    //   let x = e.clientX - rect.left;
    //   let y = e.clientY - rect.top;
    //   btn[0].style.setProperty('--x', x + 'px');
    //   btn[0].style.setProperty('--y', y + 'px');
    // });

    // Our apprch sectn

    $(".our_approach .wrppr").on("mouseenter", function (e) {
      var h = $(this).find(".hov");
      var c = $(this).find(".circle_bg");

      // console.log(c, 'test');

      var tl = gsap.timeline();

      tl.to(h, {
        css: {
          color: "#07ffcd",
        },
        ease: "power3.out",
        duration: 0.8,
      }).to(
        c, {
        scale: 1.2,
        ease: "power3.out",
        duration: 0.8,
      },
        ">-0.7"
      );
    }),
      $(".our_approach .wrppr").on("mouseleave", function (e) {
        var h = $(this).find(".hov");
        var c = $(this).find(".circle_bg");

        // console.log(c, 'test');
        var tl = gsap.timeline();

        tl.to(h, {
          css: {
            color: "#6e819c",
          },
          ease: "power3.out",
          duration: 1,
        }).to(
          c, {
          scale: 0,
          ease: "power3.out",
          duration: 1,
        },
          ">-0.9"
        );
      });

    //advantage_work new animation
    $(".advantage_work .wrppr").on("mouseenter", function (e) {
      var h = $(this).find(".hov");
      var c = $(this).find(".circle_bg");

      // console.log(c, 'test');

      var tl = gsap.timeline();

      tl.to(h, {
        css: {
          color: "#07ffcd",
        },
        ease: "power3.out",
        duration: 0.8,
      }).to(
        c, {
        scale: 1.2,
        ease: "power3.out",
        duration: 0.8,
      },
        ">-0.7"
      );
    }),
      $(".advantage_work .wrppr").on("mouseleave", function (e) {
        var h = $(this).find(".hov");
        var c = $(this).find(".circle_bg");

        // console.log(c, 'test');
        var tl = gsap.timeline();

        tl.to(h, {
          css: {
            color: "#6e819c",
          },
          ease: "power3.out",
          duration: 1,
        }).to(
          c, {
          scale: 0,
          ease: "power3.out",
          duration: 1,
        },
          ">-0.9"
        );
      });

    // estimate btn anim

    $(".estimate_form_cntnr .estimate_chkbox_wrp , .blog_category_wrpr .estimate_chkbox_wrp").on(
      "mouseenter",
      function (e) {
        var n = $(this).find(".btn_bg");

        gsap.to(n, {
          width: "100%",
          duration: 1.5,
          ease: "power3.out",
        });
      }
    ),
      $(".estimate_form_cntnr .estimate_chkbox_wrp, .blog_category_wrpr .estimate_chkbox_wrp").on(
        "mouseleave",
        function (e) {
          var n = $(this).find(".btn_bg");

          gsap.to(n, {
            width: "0%",
            duration: 1.5,
            ease: "power3.out",
          });
        }
      )
    // $(".estimate_form_cntnr .estimate_chkbox_wrp").on(
    //         "mouseenter",
    //         function (e) {
    //             var bgcls = $(this).find(".btn_bg");
    //             console.log(bgcls)
    //             gsap.to(bgcls, {
    //                 width: "100%",
    //                 duration: 1.5,
    //                 ease: "power3.out",
    //             });
    //         }
    //     ),
    //     $(".estimate_form_cntnr .estimate_chkbox_wrp").on(
    //         "mouseleave",
    //         function (e) {
    //             var bgcls = $(this).find(".btn_bg");

    //             gsap.to(bgcls, {
    //                 width: "0%",
    //                 duration: 1,
    //                 ease: "power3.out",
    //             });
    //         }
    //     );

    //  checkbox select script
    $(".estimate_chkbox_wrp input:checkbox").on("change", function () {
      if ($(this).is(":checked")) $(this).parent().addClass("toggle_active");
      else $(this).parent().removeClass("toggle_active");
    });


    // $("#estimate_frm input:checkbox").on("change",function() {
    //     $("#estimate_frm input:checkbox").attr("checked", false);
    //     $(this).attr("checked", true);
    // });
    // >>end<<
    setTimeout(() => {
      // Set up the Intersection Observer
      const observerOptions = {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: 0 // Trigger when at least 50% of the container is visible
      };

      // Start observing the container
      const techContainer = document.querySelector(".number_stack");
      const requestContainer = document.querySelector('.cmmn_txt_anim');
      if (techContainer) {
        const observer = new IntersectionObserver(handleIntersection, observerOptions);
        observer.observe(techContainer);
      }
      if (requestContainer) {
        const requestObserver = new IntersectionObserver(requestIntersection, observerOptions);
        requestObserver.observe(requestContainer);
      }
    }, 500);

    let links = gsap.utils.toArray(".top_page_nav a");
    links.forEach(a => {
      let element = document.querySelector(a.getAttribute("href")),
        linkST = ScrollTrigger.create({
          trigger: element,
          start: "top top"
        });
      ScrollTrigger.create({
        trigger: element,
        start: "top center",
        end: "bottom center",
        onToggle: self => self.isActive && setActive(a)
      });
      a.addEventListener("click", e => {
        e.preventDefault();
        gsap.to(window, {
          duration: 0.5,
          scrollTo: linkST.start,
          overwrite: "auto"
        });
      });
    });

    function setActive(link) {
      links.forEach(el => el.classList.remove("active"));
      link.classList.add("active")
    }

    /** tab move next  */
    // $('.next_tab_btn .next_btn').on('click', function () {
    //   var $tabs = $('#v-pills-tab .nav-link');
    //   var $tabContents = $('#v-pills-tabContent .tab-pane');

    //   var activeIndex = $tabs.index($tabs.filter('.active'));
    //   var nextIndex = (activeIndex + 1) % $tabs.length;

    //   $tabs.eq(activeIndex).removeClass('active').attr('aria-selected', 'false');
    //   $tabContents.eq(activeIndex).removeClass('show active');

    //   $tabs.eq(nextIndex).addClass('active').attr('aria-selected', 'true');
    //   $tabContents.eq(nextIndex).addClass('show active');
    // });

    // /**home counter anim */
    // function animateCounter(element, endValue, delay) {
    //   gsap.fromTo(element, {
    //     textContent: 0
    //   }, {
    //     textContent: endValue,
    //     duration: 1,
    //     ease: "power3.out",
    //     delay: delay,
    //     snap: { textContent: 1 },
    //     onUpdate: function () {
    //       element.textContent = Math.ceil(element.textContent) + '+';
    //     }
    //   });
    // }

    // document.querySelectorAll('.counter-number').forEach((element) => {
    //   const endValue = parseInt(element.getAttribute('data-count'), 10);
    //   animateCounter(element, endValue, 1);
    // });

    const additionalY = { val: 0 };
    let additionalYAnim;
    let offset = 0;
    const cols = gsap.utils.toArray(".col");

    cols.forEach((col, i) => {
      const images = col.childNodes;

      // DUPLICATE IMAGES FOR LOOP
      images.forEach((image) => {
        var clone = image.cloneNode(true);
        col.appendChild(clone);
      });

      // SET ANIMATION
      images.forEach((item) => {
        let columnHeight = item.parentElement.clientHeight;
        let direction = i % 2 !== 0 ? "+=" : "-="; // Change direction for odd columns

        gsap.to(item, {
          y: direction + Number(columnHeight / 2),
          duration: i + 30,
          repeat: -1,
          ease: "none",
          modifiers: {
            y: gsap.utils.unitize((y) => {
              if (direction == "+=") {
                offset += additionalY.val;
                y = (parseFloat(y) - offset) % (columnHeight * 0.5);
              } else {
                offset += additionalY.val;
                y = (parseFloat(y) + offset) % -Number(columnHeight * 0.5);
              }

              return y;
            })
          }
        });
      });
    });


    /**our timeline mobile view slider */
    // Function to update active class for year buttons
    function updateActiveClass() {
      const currentSlide = $('.timeline_slider').slick('slickCurrentSlide');
      const yearButtons = $('.year_btn');

      yearButtons.removeClass('active'); // Remove active class from all buttons
      yearButtons.eq(currentSlide).addClass('active'); // Add active class to button matching current slide
      var scrollPos = 0;
      if (yearButtons.eq(currentSlide))
        scrollPos = yearButtons.eq(currentSlide).offset().left - yearButtons.eq(currentSlide).outerWidth(true);
      $('.year_btn_wrpr').scrollLeft(scrollPos);
    }

    // Update active class on initial load and after slide change
    updateActiveClass();
    $('.timeline_slider').on('afterChange', updateActiveClass);

    //  Update active class on button click
    $('.year_btn').click(function () {
      const buttonIndex = $(this).index();
      $('.timeline_slider').slick('slickGoTo', buttonIndex);
    });



    let sections = document.querySelectorAll(".section");
    let scrollContainer = document.querySelector(".scrollContainer");

    //horizontal scroll
    let scrollTween = gsap.to(scrollContainer, {
      x: () => -(scrollContainer.scrollWidth - window.innerWidth),
      ease: "none",
      scrollTrigger: {
        trigger: '.our_timeline',
        invalidateOnRefresh: true,
        pin: true,
        pinSpacing: true,
        scrub: 1,
        start: 'top -=10%',
        end: () => "+=" + scrollContainer.scrollWidth / 2,
      },
    });

    gsap.utils.toArray(".parallax").forEach(text => {
      gsap.timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          containerAnimation: scrollTween,
          trigger: text,
          start: "left right",
          end: "left left",
          scrub: true
        }
      })
        .fromTo(text, { x: 250 }, { x: -250 }, 0)
      // .from(text.nextElementSibling, {scale: 0.8}, 0)
    });

  },
};